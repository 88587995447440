@import "../../../constants/colors.scss";

.team-performance-chart-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  background-color: $white;
  padding: 32px 28px;
  margin-top: 32px;
}
.team-performance-chart-inside-main-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.team-performance-chart-inside-container{
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
}
.team-performance-chart-info-box-main-container{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.team-performance-chart-dark-box-main-style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: fit-content;
  background-color: $mediumSlateBlue;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 4px;
  margin-left: 0px;
}

.team-performance-chart-light-box-main-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: fit-content;
  background-color: $solitude;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 4px;
  margin-right: 0px;
}