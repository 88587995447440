@import "../../../constants/colors.scss";

.selector-home-item-box-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 180px;
  width: 180px;
  height: 225px;
  padding: 15px;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 24px;
}
.selector-home-item-box-image-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  min-height: 180px;
  border-radius: 4px;
  background-color: $white;
  margin-bottom: 16px;
}
.selector-home-item-box-image-container-selected {
  @extend .selector-home-item-box-image-container;
  box-shadow: 0px 6px 12px #0000001a;
  border: 2px solid #6e62e3;
  border-radius: 8px;
}
