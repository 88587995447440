@import "../../../constants/colors.scss";
@import "rsuite/dist/rsuite.css";

.selector-home-product-filter-main-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 350px;
    border-radius: 8px;
    background-color: $white;
    padding: 20px 24px 8px;
    max-height: 520px;
    overflow: auto;
}

.selector-home-product-filter-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.selector-home-product-filter-line-style{
    height: 2px;
    width: 100%;
    background-color: $whiteSmokeDark;
    margin-top: 12px;
}

.selector-home-product-filter-boxes-container{
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.selector-home-product-filter-heading-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.selector-home-product-filter-input-text-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
}
.color-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 4px;
}
.color-filter-selected {
    border: 2px solid black;
}