@import "../../../constants/colors.scss";

.selector-home-rule-container {
    background-color: $bananaMania;
    color: $horsesNeck;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height:  calc(100% - 36px);
    min-height:  calc(100% - 36px);
    max-height: 500px;
    width: 100%;
    min-width: 100%;
    max-width: 350px;
    padding: 26px;
}

.selector-home-rule-container::-webkit-scrollbar {
  width: 4px;

}
.selector-home-rule-container::-webkit-scrollbar-track {
  background-color: $whiteSmoke;
  border-radius: 4px;
  background-clip: padding-box;
}
/* Handle */
.selector-home-rule-container::-webkit-scrollbar-thumb {
  background: $dimGray;
  border-radius: 4px;
}
/* Handle on hover */
.selector-home-rule-container::-webkit-scrollbar-thumb:hover {
  background: $nero;
}