@import "../../constants/colors.scss";

.checkbox-main-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 1px solid $darkGray;
  background-color: $white;
  border-radius: 3px;
}
.checkbox-main-active-container {
  @extend .checkbox-main-container;
  border: 1px solid $mediumSlateBlue; 
  background-color: $mediumSlateBlue;
  // background-color: $whiteSmokeDark;
}
