@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./constants/colors.scss";

body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// ::-webkit-scrollbar {
//   height: 4px;
// }
// ::-webkit-scrollbar-track {
//   background-color: $whiteSmoke;
//   border-radius: 4px;
//   background-clip: padding-box;
// }
// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: $dimGray;
//   border-radius: 4px;
// }
// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: $nero;
// }
