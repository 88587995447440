@import "../../constants/colors.scss";

.dropdown-main-container-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $white;
  border: 1px solid #EFEFEF;
  border-radius: 8px;
  padding: 10px 16px;
}
.dropdown-menu-container-style{
  position: absolute;
  top: 50px;
  right:  0px;
  width: 190px;
  background-color: $white;
  box-shadow: 0px 6px 9px #0000001A;
  border-radius: 8px;
  overflow: hidden;
}
.dropdown-menu-item-container-style{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
  &:hover {
    background-color: $ghostWhite;
  }
}