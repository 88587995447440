@import "../../../constants/colors.scss";

.notification-box-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 600px;
  height: 50px;
  border-radius: 8px;
  padding: 0px 24px;
  margin-bottom: 18px;
}

.notification-box-success-style {
  background-color: $whiteIce;
  border: 1px solid $mediumAquamarine;
}

.notification-box-error-style {
  background-color: $roseWhite;
  border: 1px solid $atomicTangerine;
}

.notification-box-warning-style {
  background-color: $cornSilk;
  border: 1px solid $kournikova;
}

.notification-box-left-side-container{
  display: flex;
  flex-direction: row;
  align-items: center;
width: 85%;
padding-right: 12px;
}

.notification-box-right-side-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}

.notification-box-status-text-style{
  padding: 0px 18px 0px 12px;
}

.notification-box-menu-button-style{
  background-color: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 360px;
  cursor: pointer;
}
