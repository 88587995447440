@import "../../constants/colors.scss";


.login-page-illustration-style{
    width: 100%;
}
.login-page-right-side-style{
background-color: $aliceBlue;
}
.login-page-input-right-side-icon-style {
    width: 20px;
    height: auto;
  }