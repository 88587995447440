@import "../../../constants/colors.scss";

.osp-item-box-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  min-width: 180px;
  height: 270px;
  padding: 15px;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 24px;
  box-shadow: 0px 3px 6px $lavender;
}
.osp-item-box-image-container{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.osp-item-box-base-button-style{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 90px;
  max-width: 150px;
  padding: 0px;
  background: transparent;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
}
.osp-item-box-primary-button-style{
  @extend .osp-item-box-base-button-style;
  border: 1px solid $mediumSlateBlue;
  color: $mediumSlateBlue;
  margin-bottom: 6px;
}
.osp-item-box-secondary-button-style{
  @extend .osp-item-box-base-button-style;
  border: 1px solid $portage;
  color: $portage;
  margin-bottom: 10px;
}
