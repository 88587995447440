@import "../../../constants/colors.scss";

.order-selection-percentage-chart-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  background-color: $white;
  padding: 32px 28px;
  margin-top: 32px;
}
.order-selection-percentage-chart-inside-main-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.order-selection-percentage-chart-inside-container{
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
}
.order-selection-percentage-chart-info-box-main-container{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.order-selection-percentage-chart-dark-box-main-style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: fit-content;
  background-color: $mediumSlateBlue;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 4px;
  margin-left: 0px;
}

.order-selection-percentage-chart-light-box-main-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: fit-content;
  background-color: $solitude;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 4px;
  margin-right: 0px;
}

.order-selection-percentage-title-container-style{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.order-selection-percentage-title-filters-container-style{
display: inline-flex;
}