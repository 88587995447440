@import "../../../constants/colors.scss";

.selector-home-product-carousel-main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.selector-home-product-carousel-container {
  display: flex;
  flex-direction: row;
}
.selector-home-product-carousel-thumb-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.selector-home-product-carousel-thumb-button-style {
  background-color: $white;
  width: 50px;
  height: 50px;
  border-radius: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  &:disabled {
    background-color: $lavender;
  }
}
.selector-home-product-carousel-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  max-height: 350px;
  width: 350px;
  max-width: 350px;
  background-color: $white;
  border: 1px solid $whiteSmoke;
  border-radius: 8px;
}
.selector-home-product-carousel-internal-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  max-height: 300px;
  width: 300px;
  max-width: 300px;
  background-color: $white;
  border: 1px solid $whiteSmoke;
  border-radius: 8px;
  overflow: hidden;
}
.selector-home-product-carousel-image-style {
  max-height: 80%;
  max-width: 80%;
}
.selector-home-product-carousel-indicator-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.selector-home-product-carousel-indicator-style {
  height: 8px;
  width: 8px;
  background-color: $whisper;
  border-radius: 360px;
  margin: 10px 5px 0px;
}
.selector-home-product-carousel-active-indicator-style {
  @extend.selector-home-product-carousel-indicator-style;
  background-color: $mediumSlateBlue;
}
