@import "../../constants/colors.scss";

.loader-main-container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  background-color: $blackTransparent;
}
