@import "../../constants/colors.scss";

.input-main-container-style {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
  padding-bottom: 36px;
  position: relative;
}
.input-container-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  border: 1px solid $hawkesBlue;
  border-radius: 8px;
  background-color: red;
  overflow: hidden;
}
.input-style {
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 12px;
  font-family: "Manrope";
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: $black;
  &::placeholder {
    color: $dimGray;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
}
.input-icon-container-style {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 39px;
  height: 100%;
  right: 0px;
  background-color: $white;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #F0F0F0;
    
  }
}

.input-error-container-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  bottom: 14px;
}
