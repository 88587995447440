@import "../../constants/colors.scss";

.work-hours-title-container-style{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.work-hours-days-name-main-container{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.work-hours-days-box-style{
    width: calc(100% / 7);
    padding: 0px 6px;
}

.work-hours-days-list-main-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.work-hours-days-info-main-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 36px;
    padding: 0px 6px;
}
.work-hours-days-info-text-container{
    display: inline-flex;
    align-items: center;
}
.work-hours-days-dot-medium-slate-blue-style{
    width: 6px;
  height: 6px;
  border-radius: 360px;
  background-color: $mediumSlateBlue;
  margin-right: 6px;
}
.work-hours-days-dot-saffron-style{
    width: 6px;
  height: 6px;
  border-radius: 360px;
  background-color: $saffron;
  margin-right: 6px;
}
