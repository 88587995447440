@import "../../../constants/colors.scss";

.team-performance-table-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  background-color: $white;
}
.team-performance-table-item-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 56px;
  padding: 0px 35px;
}
.team-performance-table-bottom-border-radius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.team-performance-table-item-border-bottom {
  border-bottom: 1px solid $whisper;
}
.team-performance-table-item-container-hover-style {
  &:hover {
    background-color: $ghostWhite;
  }
}

.display-flex-align-center-justify-start {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  margin-right: 12px;
}
.team-performance-table-item-first-container {
  @extend .display-flex-align-center-justify-start;
  width: 45%;
}
.team-performance-table-item-second-container {
  @extend .display-flex-align-center-justify-start;
  width: 25%;
}
.team-performance-table-item-third-container {
  @extend .display-flex-align-center-justify-start;
  width: 25%;
}
.team-performance-table-item-fourth-container {
  @extend .display-flex-align-center-justify-start;
  width: 30%;
}
