@import "../../constants/colors.scss";

.calendar-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $white;
  border: 1px solid #EFEFEF;
  border-radius: 8px;
  cursor: pointer;
}
.calendar-main-text-container {
  display: inline-flex;
  align-items: center;
  padding: 10px 16px;
}
.calendarIconContainer {
  display: flex ;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 12px;
}
.calendar-component-container{
  position: absolute;
  top: 50px;
    right: 0px;
}

.react-datepicker-wrapper {
  display: flex !important;
  height: 100% !important;
  width: 100% !important;
  z-index: 1 !important;
  background: transparent !important;
}

.react-datepicker__input-container {
  position: relative !important;
  display: inline-block !important;
  width: 100% !important;
  background: transparent !important;
}

.react-datepicker__input-container > input {
  padding: 0px 10px !important;
  height: 100% !important;
  width: calc(100% - 20px) !important;
  border: none !important;
  background: transparent !important;
  cursor: pointer !important;
  font-family: "Mulish" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: $grey !important;
}

.react-datepicker-ignore-onclickoutside {
  outline: none;
  background: transparent;
}
