@import "../../constants/colors.scss";

.selector-home-main-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.selector-home-main-container::-webkit-scrollbar {
  display: none;
}
.selector-home-main-inside-container {
  padding: 16px 25px 25px;
  background-color: $aliceBlue;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.selector-home-main-inside-container::-webkit-scrollbar {
  display: none;
}
.selector-home-double-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selector-home-double-button-main-container {
  display: flex;
  flex-direction: row;
  background-color: $white;
  width: fit-content;
  border-radius: 8px;
  padding: 2px 5px;
}
.selector-home-double-button-unselected-style {
  width: fit-content;
  background-color: $white;
  color: $mediumSlateBlue;
}
.selector-home-double-button-selected-style {
  width: fit-content;
}

.selector-home-horizontal-list-style {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  min-height: 250px;
  margin-bottom: 36px;
}
.selector-home-horizontal-list-style::-webkit-scrollbar {
  height: 4px;
}
.selector-home-horizontal-list-style::-webkit-scrollbar-track {
  background-color: $whiteSmoke;
  border-radius: 4px;
  background-clip: padding-box;
}
/* Handle */
.selector-home-horizontal-list-style::-webkit-scrollbar-thumb {
  background: $dimGray;
  border-radius: 4px;
}
/* Handle on hover */
.selector-home-horizontal-list-style::-webkit-scrollbar-thumb:hover {
  background: $nero;
}
.selector-home-bottom-container-style {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}
.selector-home-bottom-product-carousel-text-container-style{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0px;
}
.selector-home-bottom-product-carousel-container-style{
  display: flex;
  flex-direction: row;
  justify-content: center;
    align-items: center;
}
.selector-home-bottom-product-carousel-yes-button-style{
  max-width: 90px;
  font-size: 14px;
  font-weight: 500;
}
.selector-home-bottom-product-carousel-no-button-style{
  max-width: 90px;
  background-color: $white;
  color: $mediumSlateBlue;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 500;
}
.selector-home-bottom-product-carousel-reject-button-style{
  max-width: 160px;
  background-color: $white;
  color: $mediumSlateBlue;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
}
