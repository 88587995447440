@import "../../constants/colors.scss";

.order-selection-percentage-horizontal-list-style {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  min-height: 300px;

  &::-webkit-scrollbar {
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: $whiteSmoke;
    border-radius: 4px;
    background-clip: padding-box;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $dimGray;
    border-radius: 4px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $nero;
  }
}
