@import "../../../constants/colors.scss";

.selector-home-header-container {
    background-color: $white;
    display: flex;
    flex-direction: row;
    height: 50px;
    min-height: 50px;
    width: 100%;
    padding: 0px 26px;
    justify-content: space-between;
    align-items: center;
}

.selector-home-header-user-profile-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.selector-home-header-user-profile-text-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 6px;
}