@import "../../../constants/colors.scss";

.calendar-box-main-container {
  display: flex;
  flex-direction: column;
  width: calc(100% / 7);
  padding: 12px 6px;
}
.calendar-box-internal-main-container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 8px;
  padding: 12px 6px;
}
.calendar-box-internal-main-sunday-container {
  @extend .calendar-box-internal-main-container;
  background-color: $roseWhite;
}
.calendar-box-internal-top-container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.calendar-box-internal-bottom-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.calendar-box-internal-bottom-text-container{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.calendar-box-internal-bottom-dot-saffron-style{
  width: 6px;
  height: 6px;
  border-radius: 360px;
  background-color: $saffron;
  margin-left: 3px;
}
.calendar-box-internal-bottom-dot-medium-slate-blue-style{
  width: 6px;
  height: 6px;
  border-radius: 360px;
  background-color: $mediumSlateBlue;
  margin-right: 3px;
}
