@import "../../../constants/colors.scss";

.sidebar-main-container {
 max-width: 260px;
 width: 100%;
}

.sidebar-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  padding: 0px 20px;
  cursor: pointer;
}
.sidebar-item-icon-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}
.sidebar-number-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 35px;
  background-color: $mediumSlateBlue;
  border-radius: 8px;
  margin-left: 35px;
}
